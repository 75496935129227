@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Montserrat Medium';
  font-weight: 500;
  src: url('https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD-w.ttf'); /* IE9 Compat Modes */
}
body {
  font-family: 'Montserrat';
}
